import axios from 'axios'

const ACTION_URI = '/actions/inquiry-module/inquiry/validate-email'

const validEmail = (email) => {
  if (email === '') return true

  return new Promise((resolve, reject) => {
    const data = { email }
    data[window.csrfTokenName] = window.csrfTokenValue

    axios({
      url: ACTION_URI,
      method: 'POST',
      data,
    })
      .then(response => {
        const isValid = response.data && response.data.success && response.data.valid === true
        resolve(isValid)
      })
      .catch(error => {
        reject()
      })
  })
}

export { validEmail }
