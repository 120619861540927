<template>
  <a :href="'#' + to" @click.prevent="onClick">
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      $target: null,
      pageHasTarget: false
    }
  },
  mounted() {
    if (this.$target = document.querySelector(`a[name='${this.to}']`)) {
      this.pageHasTarget = true
    } else {
      this.pageHasTarget = false
    }
  },
  methods: {
    scrollToTarget() {
      window.scrollTo({ top: this.$target.offsetTop, behavior: 'smooth' })
    },
    navigateToTarget() {
      const $homeRel = document.querySelector('link[rel="home"]')
      if (!$homeRel) {
        return
      }

      const homeUrl = $homeRel.getAttribute('href')
      const targetUrl = homeUrl + '#' + this.to
      window.location.href = targetUrl
    },
    onClick() {
      if (this.pageHasTarget) {
        this.scrollToTarget()
      } else {
        this.navigateToTarget()
      }
    }
  }
}
</script>
