<template>
  <div
    :class="['whitespace-nowrap relative text-sm md:bg-white md:z-10', { 'shadow-lg': isOpen }]"
    @mouseleave="isOpen = false"
    style="transition: opacity .2s ease, transform .2s ease;">
    <div @click="toggle()" class="w-full cursor-pointer z-10 relative below-md:inline md:bg-white md:pl-4 md:pr-2">
      <slot name="current" />
      <span class="below-md:hidden">
        &nbsp;<span v-show="isOpen" class="inline-block text-2xs">▲</span><span v-show="!isOpen" class="inline-block text-2xs">▼</span>
      </span>
    </div>
    <transition name="vertical">
    <div v-show="isOpen || isMobileViewport" class="md:absolute md:bg-white md:w-full md:pl-4 md:pr-2 md:pb-2 md:pt-5 md:-mt-4 below-md:inline">
      <slot />
    </div>
    </transition>
  </div>
</template>

<script>
const mqMobile = window.matchMedia('(max-width: 768px)')

export default {
  data() {
    return {
      isOpen: false,
      isMobileViewport: false,
      selectedLang: null
    }
  },
  methods: {
    toggle() {
      if (this.isMobileViewport) return
      this.isOpen = !this.isOpen
    },
    checkScreen() {
      this.isMobileViewport = mqMobile.matches
    }
  },
  mounted() {
    const cloaked = this.$el.querySelectorAll('.cloak')
    for (let i = 0; i < cloaked.length; i++) {
      cloaked[i].classList.remove('cloak')
    }

    mqMobile.addListener(this.checkScreen)
    this.checkScreen()
  }
}
</script>
