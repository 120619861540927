import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    selectedVehicles: {},
    mobileNavOpen: false
  },
  mutations: {
    selectVehicle(state, vehicle) {
      if (!state.selectedVehicles.hasOwnProperty(vehicle.id)) {
        state.selectedVehicles = {}
        Vue.set(state.selectedVehicles, vehicle.id, vehicle)
      }
    },
    addVehicle(state, vehicle) {
      if (!state.selectedVehicles.hasOwnProperty(vehicle.id)) {
        Vue.set(state.selectedVehicles, vehicle.id, vehicle)
      }
    },
    removeVehicle(state, vehicle) {
      if (state.selectedVehicles.hasOwnProperty(vehicle.id)) {
        Vue.delete(state.selectedVehicles, vehicle.id)
      }
    },
    openMobileNav(state) {
      state.mobileNavOpen = true
    },
    closeMobileNav(state) {
      state.mobileNavOpen = false
    },
    toggleMobileNav(state) {
      state.mobileNavOpen = !state.mobileNavOpen
    }
  },
  getters: {
    mobileNavOpen(state) {
      return state.mobileNavOpen
    },

    // VCR/TR/LR: One vehicle
    isVehicleSelected(state) {
      return Object.keys(state.selectedVehicles).length > 0
    },
    selectedVehicle(state) {
      return Object.values(state.selectedVehicles)[0]
    },

    // MiniLease: Multiple vehicles
    selectionCount(state) {
      return Object.keys(state.selectedVehicles).length
    }
  }
})
