import { INQUIRY_TYPE, HANDOVER_TYPE } from '../enums'
import { required, email, sameAs, helpers } from 'vuelidate/lib/validators'
import { validEmail } from './valid-email'

const phone = helpers.regex('phone', /^\+?[0-9]{2}[0-9 ]+$/)

const baseValidations = {
  message: {},
  contactLanguage: { required },
  termsAccepted: { sameAs: sameAs(() => true) }
}

const clientValidations = {
  firstName: { required },
  lastName: { required },
  company: {},
  address: { required },
  postalCode: { required },
  city: { required },
  email: { required, email, validEmail },
  phone: { required }
}

const relaxedClientValidations = {
  firstName: { required },
  lastName: { required },
  company: {},
  address: {},
  postalCode: {},
  city: {},
  email: { required, email, validEmail },
  phone: { required }
}

const rentalValidations = {
  startDate: {},
  endDate: {},
  handoverStation: {},
  delivery: {
    address: {},
    postalCode: {},
    city: {}
  }
}

const dealerValidations = {
  company: {},
  firstName: {},
  lastName: {},
  email: { email },
  phone: {}
}

function setRequired(validations, except = []) {
  for (const p in validations) {
    if (except.includes(p)) continue
    if (Object.keys(validations[p]).length <= 1) {
      Object.assign(validations[p], { required })
    }
  }
}

export const getValidations = function(inquiryMeta) {
  const validations = {
    client: inquiryMeta.type === INQUIRY_TYPE.OFFER ? clientValidations : relaxedClientValidations,
    rental: JSON.parse(JSON.stringify(rentalValidations)),
    dealer: JSON.parse(JSON.stringify(dealerValidations)),
    ...baseValidations
  }

  if (inquiryMeta.type === INQUIRY_TYPE.OFFER) {
    setRequired(validations.rental)

    if (inquiryMeta.handoverType === HANDOVER_TYPE.HOME_DELIVERY) {
      validations.rental.handoverStation = {}

      if (inquiryMeta.isDeliveryWithDifferentAddress) {
        setRequired(validations.rental.delivery)
      }
    }
  } else {
    validations.message = { required }
  }

  if (inquiryMeta.isDealer) {
    // Require dealer details
    setRequired(validations.dealer)

  }

  let v = {
    inquiry: validations
  }
  if (inquiryMeta.type === INQUIRY_TYPE.OFFER) {
    v.selectedVehicles = { required }
  } else {
    v.selectedVehicles = {}
  }

  return v
}

