class PageLoadAnchor {
  constructor() {
    const hash = window.location.hash
    if (!hash) {
      return
    }

    this.handleHash(hash)
  }

  handleHash(hash) {
    const target = hash.substring(1)
    const $target = document.querySelector(`a[name='${target}']`)
    if (!$target) {
      return
    }

    const targetTop = $target.getBoundingClientRect().top

    window.addEventListener('load', () => {
      window.scrollTo({ top: targetTop })
    })
  }
}

export default new PageLoadAnchor
