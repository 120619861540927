<template>
  <div class="tooltip relative">
    <span class="pl-px block" @touchstart="show" @mouseenter="show" @mouseleave="hide" @touchend="hide">
      <svg class="h-5 w-5 mr-2 mt-px"><use xlink:href="#info-icon--sprite" /></svg>
    </span>
    <transition name="vertical-xs">
      <div v-if="isOpen">
        <div class="tooltip-body" ref="body">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from 'vue'
import '@/assets/svg/icons/info-icon.svg?sprite'

export default {
  props: {
    labels: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    show() {
      this.isOpen = true
    },

    hide() {
      this.isOpen = false
    }
  }
}
</script>

<style>
.tooltip {
  @apply relative;
}

.tooltip-body {
  @apply absolute bottom-0 left-0 px-4 py-3 text-left text-xs bg-white shadow-lg;
  transform: translate(-80%, -2rem);

  &:after {
    @apply block bg-white w-3 h-3 absolute;
    content: '';
    bottom: 0;
    right: 14%;
    transform: translate(50%, 50%) rotate(45deg);
  }
}
</style>
