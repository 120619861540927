<template>
  <div>
    <a :href="href" :class="classes" @click.prevent="open">
      <slot />
    </a>
    <div v-if="isOpen" class="fixed inset-0 w-full h-full bg-overlay z-50 flex justify-center items-center">
      <div class="max-w-lg px-8 py-8 bg-white mo:mx-4 rounded">
        <p class="mb-5">{{ labels.externalLinkText }}</p>
        <div class="flex flex-col sm:flex-row justify-center">
          <a class="button button--pill button--uppercase mo:mb-2 sm:mr-4" :href="href" target="_blank" @click="close">
            {{ labels.externalLinkContinue }}
          </a>
          <button class="button button--pill button--uppercase button--inverted" @click="close">
            {{ labels.externalLinkCancel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    href: String,
    classes: String,
    labels: Object
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    open() {
      document.querySelector('html').classList.add('freeze')
      this.isOpen = true
    },
    close() {
      document.querySelector('html').classList.remove('freeze')
      this.isOpen = false
    }
  }
}
</script>
