<template>
<div @click="toggle()" :class="['burger-btn', { 'is-open': mobileNavOpen }]">
  <span class="text-md font-medium" :style="{ opacity: mobileNavOpen ? 0 : 1 }">Menu</span>
  <span class="closeBtn" v-show="mobileNavOpen" />
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  methods: {
    toggle() {
      this.$store.commit('toggleMobileNav')
    },
    render() {
      if (this.mobileNavOpen) {
        document.documentElement.classList.add('menu')
      } else {
        document.documentElement.classList.remove('menu')
      }
    }
  },
  computed: {
    ...mapGetters([
      'mobileNavOpen',
    ])
  },
  watch: {
    mobileNavOpen() {
      this.render()
    }
  }
}
</script>

<style scoped>
.burger-btn {
  @apply h-10 relative cursor-pointer flex justify-center items-center;

  & .closeBtn {
    @apply w-5 h-8 block absolute right-0;
  }

  & .closeBtn:before,
  & .closeBtn:after {
    @apply block w-full h-2px bg-black absolute;
    content: '';
    top: 50%;
    left: 0;
  }

  & .closeBtn:before {
    margin-top: -6px;
    transform: translate(0, 6px) rotate(45deg);
  }

  & .closeBtn:after {
    margin-top: 6px;
    transform: translate(0, -6px) rotate(-45deg);
  }
}
</style>
