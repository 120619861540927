const INQUIRY_TYPE = {
  QUESTION: 'inquiry-type-question',
  OFFER: 'inquiry-type-offer'
}

const HANDOVER_TYPE = {
  COLLECTION: 'handover-type-collection',
  HOME_DELIVERY: 'handover-type-home-delivery',
  DEALER_DELIVERY: 'handover-type-dealer-delivery'
}

export { HANDOVER_TYPE, INQUIRY_TYPE }
