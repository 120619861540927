class TrackingHelper {
  getClientId() {
    if (!window.ga || !window.ga.getAll || window.ga.getAll().length === 0) {
      return false
    }

    const tracker = window.ga.getAll()[0]
    if (tracker) {
      return tracker.get('clientId')
    }

    return false
  }

  pushEvent(data) {
    if (window.dataLayer) {
      window.dataLayer.push(data)
    }
  }
}

export default new TrackingHelper()
