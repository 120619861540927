import MobileDetect from 'mobile-detect'

const md = new MobileDetect(window.navigator.userAgent)

export default {
  computed: {
    isMobile() {
      return md.mobile()
    }
  }
}
